<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact" v-if="filter">
        <div class="card-header">
          <div class="row my-2 flex-wrap align-items-center">
            <div class="col">
              <input
                type="text"
                v-model="id"
                v-debounce:400ms="myFn"
                class="form-control"
                placeholder="Id"
              />
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.contract__client__full_name__icontains"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Mijoz nomi"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.reg_number__icontains"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Raqami"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.contract__contract_number__icontains"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Shartnoma"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-2">
              <div class="input-icon">
                <input
                  type="text"
                  v-model="sections.filters.contract__client__inn__icontains"
                  v-debounce:400ms="myFn"
                  class="form-control"
                  placeholder="Inn"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-3">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="sections.filters.oper_date_after"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    :value="sections.filters.oper_date_after | formatDate"
                    type="text"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @change="myFn"
                    class="form-control"
                    placeholder="Sanadan"
                  />
                </template>
                <!-- :min="sections.filters.oper_date_before || null" -->
                <v-date-picker v-model="sections.filters.oper_date_after" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      () => {
                        $refs.dialog.save(sections.filters.oper_date_after);
                        myFn();
                      }
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
            <div class="col-3">
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="sections.filters.oper_date_before"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <input
                    :value="sections.filters.oper_date_before | formatDate"
                    type="text"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @input="myFn"
                    class="form-control"
                    placeholder="Sanagacha"
                  />
                </template>
                <!-- :max="sections.filters.oper_date_after || null" -->
                <v-date-picker v-model="sections.filters.oper_date_before" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false"> Cancel </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      () => {
                        $refs.dialog2.save(sections.filters.oper_date_before);
                        myFn();
                      }
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>

            <div class="col">
              <a @click="reset" class="btn btn-primary font-weight-bolder">
                {{ $t("BREADCRUMBS.CLEAN") }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card card-custom gutter-b example example-compact">
        <div class="card-header d-flex justify-content-between align-items-center">
          <!-- <div class="card-title">
            <h3 class="card-label">{{ $t('MENU.SALE.ACCOUNT_INVESTMENT') }}</h3>
          </div> -->
          <v-row class="m-0 align-items-center">
            <div class="mb-2 mr-4">
              <v-menu
                ref="yearMenuRef"
                v-model="menuDate.year"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Hisobot yili"
                    :nudge-right="40"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    clearable
                    :value="
                      filterDate.oper_date__year &&
                      `${filterDate.oper_date__year}-01-01` | yearOnly
                    "
                    @input="onInput(arguments[0], 'oper_date__year')"
                  />
                </template>
                <v-date-picker
                  ref="yearPickerRef"
                  :value="`${filterDate.oper_date__year || currentYear}-01-01`"
                  locale="ru"
                  type="year"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="2010-01-01"
                  @click:year="save(arguments[0], 'oper_date__year')"
                ></v-date-picker>
              </v-menu>
            </div>

            <div class="mb-2 mr-4">
              <v-menu
                ref="yearMonthRef"
                v-model="menuDate.month"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="ml-2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Oy"
                    :nudge-right="40"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :value="
                      filterDate.oper_date__month &&
                      `${filterDate.oper_date__year || currentYear}-${
                        filterDate.oper_date__month || '01'
                      }-01` | monthOnly
                    "
                    hide-details
                    clearable
                    @input="onInput(arguments[0], 'oper_date__month')"
                  />
                </template>
                <v-date-picker
                  ref="monthPickerRef"
                  :value="`${filterDate.oper_date__year || currentYear}-${
                    filterDate.oper_date__month || '01'
                  }-01`"
                  locale="ru"
                  no-title
                  type="month"
                  min="2010-01-01"
                  no-date-picker-header
                  @click:month="save(arguments[0], 'oper_date__month')"
                />
              </v-menu>
            </div>

            <div class="mb-2 mr-1">
              <div class="d-flex align-items-center">
                <el-button
                  style="min-width: 5rem; background-color: #ecf5ff; color: #409eff"
                  @click="onSearchByDate"
                >
                  Izlash
                </el-button>
              </div>
            </div>
          </v-row>
          <v-btn small @click="filter = !filter" class="ml-2" color="warning">
            Filter
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>

        <router-view
          ref="childRef"
          :filter-props="sections.filters"
          @change-page="myFn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { takeFilledKeyValue } from "@/utils";
import { monthOnly, yearOnly } from "@/utils/filters/dateFormats.js";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

export default {
  data() {
    return {
      currentYear,
      menuDate: {
        year: false,
        month: false,
      },

      filterDate: {
        oper_date__year: +this.$route.query.oper_date__year || currentYear,
        oper_date__month: +this.$route.query.oper_date__month || currentMonth,
      },

      contract: "",
      menu2: false,
      date: "",
      filter: false,
      page: 1,
      itemsPerPage: 10,
      sections: {
        filters: {
          id: "",
          oper_date_after: "",
          oper_date_before: "",
          contract__client__full_name__icontains: "",
          reg_number__icontains: "",
          contract__contract_number__icontains: "",
          contract__client__inn__icontains: "",
        },
      },
    };
  },
  filters: {
    monthOnly,
    yearOnly,
  },
  // created() {
  //   if (this.$store.state.requests.filterData.data !== undefined) {
  //     const data = this.$store.state.requests.filterData.data;
  //     if (
  //       this.$store.state.requests.filterData.path.substring(
  //         0,
  //         this.$route.path.lastIndexOf("/")
  //       ) == this.$route.path.substring(0, this.$route.path.lastIndexOf("/"))
  //     ) {
  //       this.filter = true;
  //       (this.contract__client__full_name__icontains =
  //         data.contract__client__full_name__icontains),
  //         (this.reg_number__icontains = data.reg_number__icontains),
  //         (this.contract__contract_number__icontains =
  //           data.contract__contract_number__icontains),
  //         (this.contract__client__inn__icontains = data.contract__client__inn__icontains),
  //         (this.id = data.id);
  //     } else {
  //       this.filter = false;
  //       this.$store.commit("setFilterData", {
  //         data: undefined,
  //         path: "",
  //       });
  //     }
  //   }
  // },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("BREADCRUMBS.SOLD") },
      { title: this.$t("MENU.SALE.ACCOUNT_INVESTMENT") },
    ]);
  },
  computed: {
    headers() {
      return [
        { text: this.$t("TABLE_HEADER.CLIENT"), value: "zayavka_owner" },
        { text: this.$t("TABLE_HEADER.CONTRACT_NUMBER"), value: "contract" },
        { text: this.$t("TABLE_HEADER.PROVIDER"), value: "deliver" },
        { text: this.$t("TABLE_HEADER.PRICE"), value: "price" },
        { text: "Boshlanishi", value: "start_date" },
        { text: this.$t("TABLE_HEADER.DIRECTION"), value: "direction" },
        { text: "", value: "action", sortable: false },

        { text: "ID", value: "id" },
        { text: this.$t("TABLE_HEADER.CLIENT"), value: "client_name" },
        { text: "Raqami", value: "reg_number" },
        { text: "Shartnoma", value: "contract" },
        { text: this.$t("TABLE_HEADER.INN"), value: "client_inn" },
        { text: "Sana", value: "created_at" },
        {
          text: this.$t("TABLE_HEADER.PRICE"),
          value: "get_factura_amount.price",
        },
        { text: this.$t("TABLE_HEADER.NDS"), value: "get_factura_amount.nds" },
        {
          text: this.$t("TABLE_HEADER.PRICE_WITH_NDS"),
          value: "get_factura_amount.price_with_nds",
        },
        {
          text: this.$t("TABLE_HEADER.ITEM"),
          value: "get_factura_amount.count",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  methods: {
    reset() {
      this.contract = "";
      this.date = "";
      this.sections.filters.contract__client__full_name__icontains = "";
      this.sections.filters.reg_number__icontains = "";
      this.sections.filters.contract__contract_number__icontains = "";
      this.sections.filters.contract__client__inn__icontains = "";
      this.sections.filters.oper_date_after = "";
      this.sections.filters.oper_date_before = "";
      this.id = "";
      this.$store.commit("setFilterData", {
        data: undefined,
        path: "",
      });

      let query = {};

      Object.keys(this.filterDate).forEach((key) => {
        query[key] = this.filterDate[key];
      });

      this.$router.push({
        params: { id: 1 },
        query: {
          ...query,
          rn: Math.random().toString().slice(2, 6),
        },
      });
      this.myFn();
    },
    myFn() {
      let data = {};
      Object.keys(this.sections.filters).forEach((key) => {
        if (
          ["oper_date_before", "oper_date_after"].includes(key) &&
          this.sections.filters[key]
        ) {
          this.filterDate.oper_date__year = "";
          this.filterDate.oper_date__month = "";
        }

        data[key] = this.sections.filters[key];
      });

      Object.keys(this.filterDate).forEach((key) => {
        data[key] = this.filterDate[key];
      });
      if (this.id !== "") {
        data.id = this.id;
      }
      this.menu2 - false;

      data = takeFilledKeyValue(data);
      this.$store.commit("setFilterData", {
        data: data,
        path: this.$route.path,
      });
      // this.$store.dispatch("allFacturasFilter", data);
      // if (this.$route.path !== "/allfactura/1") {
      //   this.$router.push("/allfactura/" + "1");
      // }

      this.$refs.childRef.getPostData(1, data);
    },

    ...mapMutations(["FILTER_DIALOG_TOGGLE", "SET_FILTER"]),

    onSearchByDate() {
      const props = takeFilledKeyValue(this.filterDate);

      this.$router.push(
        {
          name: "Allfactura Page",
          params: { id: 1 },
          query: { ...props },
        },
        () => {}
      );
    },

    save(dateTimeValue, datePickerPropKey) {
      let t = dateTimeValue;

      if (datePickerPropKey == "oper_date__month" && t) {
        t = parseInt(dateTimeValue.slice(5));
      }

      this.filterDate[datePickerPropKey] = t;
    },

    onInput(t = null, arg) {
      // console.log(t, arg)
      this.filterDate[arg] = t;

      // for emit filter props to parent
      // this.onSearchByDate()
    },
  },
  watch: {
    "menuDate.year": {
      handler(val) {
        if (val) setTimeout(() => (this.$refs.yearPickerRef.activePicker = "YEAR"));
      },
    },
    "menuDate.month": {
      handler(val) {
        if (val) setTimeout(() => (this.$refs.monthPickerRef.activePicker = "MONTH"));
      },
    },
  },
};
</script>
<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-y: unset !important;
  overflow-x: unset !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
}
</style>
